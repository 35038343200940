import apiClient from '@/services/axios';
import store from 'store';
// import { notification } from 'ant-design-vue';
/* import _ from 'lodash'; */
export async function login(username, password) {
  store.remove('accessToken');
  return apiClient
    .post('/api/control/login', {
      username,
      password,
      client_id: "2",
      client_secret: "ic2Ov2kwtwmxVSxbj79dGiupCcLfVLFzIAgz8khM"
    })
    .then((res) => {
      console.log('/api/login res -->', res);
      if (res && res.data && res.data.success ) {
        store.set('accessToken', res.data.data.access_token);
      }
      return res;
    })
    .catch((err) => {
      console.log('/api/login err -->', err);
      const warning_data = {};
      /* if (_.isArray(res.data.messages) && !_.isEmpty(res.data.messages)) {
        _.filter(res.data.messages, (v) => {
          if (!warning_data.message) {
            warning_data.message = v;
          } else {
            warning_data.message += `, ${v}`;
          }
        });
      }
      if (data.error_description) {
        warning_data.description = data.error_description;
      }
      console.log('warning_data -->', warning_data); */
      warning_data.message = 'เข้าสู่ระบบไม่สำเร็จ!';
      warning_data.description = 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง!';
      if (warning_data.message || warning_data.description) {
        // notification.warning(warning_data);
      }
      return false;
    });
}
export async function checkLogin(member_id) {
  store.remove('accessToken');
  return apiClient
    .post('/api/checkLogin', {
      member_id,
    })
    .then((res) => {
      console.log('/api/checkLogin res -->', res);
      if (res && res.data && res.data.result == "complete") {
        store.set('check_pin', res.data.complete.check_pin);
      }
      return res;
    })
    .catch((err) => {
      console.log(`/api/checkLogin res err -->`, err);
    });
}
export async function register(username, password, name) {
  return apiClient
    .post('/auth/register', {
      username,
      password,
      name,
    })
    .then((res) => {
      if (res) {
        const {
          accessToken
        } = res.data;
        if (accessToken) {
          store.set('accessToken', accessToken);
        }
        return res.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}
export async function currentAccount() {
  return apiClient
    .get('/api/control/auth/get-profile')
    .then((res) => {
      if (res && res.data) {
        /* const { accessToken } = res.data;
        if (accessToken) {
          store.set('accessToken', accessToken);
        } */
        return res.data;
      }
      return false;
    })
    .catch((err) => console.log(err));
}
export async function logout() {
  return apiClient
    .get('/api/control/auth/logout')
    .then(() => {
      store.remove('accessToken');
      return true;
    })
    .catch((err) => console.log(err));
}
export async function get(path) {
  return apiClient
    .get(path)
    .then((res) => {
      /* console.log(`${path} res -->`, res);
      if (res && res.data && res.data.result === 1) {
        return res.data;
      }
      return false; */
      return res;
    })
    .catch((err) => {
      console.log(`${path} err -->`, err);
    });
}
export async function post(path, data, type) {
  return apiClient
    .post(path, data, type)
    .then((res) => {
      console.log(`${path} res -->`, res);
      /* if (res && res.data) {
        if (res.data.result === 1) {
          return res.data;
        } else if (res.data.result === 0) {
          const warning_data = {};
          if (_.isArray(res.data.messages) && !_.isEmpty(res.data.messages)) {
            _.filter(res.data.messages, (v) => {
              if (!warning_data.message) {
                warning_data.message = v;
              } else {
                warning_data.message += `, ${v}`;
              }
            });
          }
          // if (data.error_description) {
          //   warning_data.description = data.error_description;
          // }
          // console.log('warning_data -->', warning_data);
          if (warning_data.message || warning_data.description) {
            notification.warning(warning_data);
          }
        }
      }
      return false; */
      return res;
    })
    .catch((err) => {
      console.log(`${path} err -->`, err);
    });
}