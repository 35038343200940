import axios from 'axios';
import store from 'store';
// import { notification } from 'ant-design-vue';
const url = window.location.href;
var apiUrl = '';
// const full_path = window.location.href.split('://');
if (url.search('service-dev.1577app.com') != '-1') {
  apiUrl = `https://api-dev.1577app.com`;
} else if (url.search('service.1577app.com') != '-1') {
  apiUrl = `https://api.1577app.com`;
} else {
  apiUrl = `https://api-dev.1577app.com`;
}
const apiClient = axios.create({
  baseURL: apiUrl,
  /* timeout: 1000,
  headers: { 'X-Custom-Header': 'foobar' } */
});
apiClient.interceptors.request.use((request) => {
  const accessToken = store.get('accessToken');
  if (accessToken) {
    /* request.headers.AccessToken = accessToken; */
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
});
apiClient.interceptors.response.use(undefined, (error) => {
  // Errors handling
  const {
    response
  } = error;
  const {
    data
  } = response;
  if (data) {
    const warning_data = {};
    if (data.error) {
      warning_data.message = data.error;
    }
    if (data.error_description) {
      warning_data.description = data.error_description;
    }
    console.log('warning_data -->', warning_data);
    if (warning_data.message || warning_data.description) {
      // notification.warning(warning_data);
    } else {
      return response;
    }
  }
});
export default apiClient;