import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
// import * as firebase from '@/services/firebase';
import * as jwt from '@/services/jwt';
import store from 'store';
const mapAuthProviders = {
  // firebase: {
  //   login: firebase.login,
  //   register: firebase.register,
  //   currentAccount: firebase.currentAccount,
  //   logout: firebase.logout,
  // },
  jwt: {
    login: jwt.login,
    checkLogin: jwt.checkLogin,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
    get: jwt.get,
    post: jwt.post,
  },
};
Vue.use(Vuex);
export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    role: '',
    email: '',
    avatar: '',
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      });
    },
  },
  actions: {
    LOGIN({
      commit,
      dispatch,
      rootState
    }, {
      payload
    }) {
      commit('SET_STATE', {
        loading: true,
      });
      const login = mapAuthProviders[rootState.settings.authProvider].login;
      console.log('payload', payload);
      login(payload.data.username, payload.data.password, Vue.prototype.$os).then((res) => {
        if (res && res.data && res.data.success) {
          dispatch('LOAD_CURRENT_ACCOUNT');
        }
        commit('SET_STATE', {
          loading: false,
        });
        console.log('res-->', res);
        payload.callback(res);
      });
    },
    CHECK_LOGIN({
      commit,
      rootState
    }, {
      payload
    }) {
      commit('SET_STATE', {
        loading: true,
      });
      const checkLogin = mapAuthProviders[rootState.settings.authProvider].checkLogin;
      console.log('checkLogin', checkLogin);
      checkLogin(payload.data.member_id).then((res) => {
        commit('SET_STATE', {
          loading: false,
        });
        payload.callback(res);
      });
    },
    REGISTER({
      commit,
      dispatch,
      rootState
    }, {
      payload
    }) {
      const {
        email,
        password,
        name
      } = payload;
      commit('SET_STATE', {
        loading: true,
      });
      const register =
        mapAuthProviders[rootState.settings.authProvider].register;
      register(email, password, name).then((success) => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT');
          Vue.prototype.$notification.success({
            message: 'Succesful Registered',
            description: 'You have successfully registered!',
          });
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          });
        }
      });
    },
    LOAD_CURRENT_ACCOUNT({
      commit,
      rootState,
    }) {
      if (store.get('accessToken')) {
        commit('SET_STATE', {
          loading: true,
        });
        const currentAccount =
          mapAuthProviders[rootState.settings.authProvider].currentAccount;
        currentAccount().then((res) => {
          console.log('currentAccount res-->', res);
          if (res) {
            console.log('if currentAccount res-->', res);
            /* const { id, email, name, avatar, role } = res; */
            commit('SET_STATE', {
              /* id,
              name,
              email,
              avatar,
              role, */
              ...res,
              authorized: true,
              loading: false,
            });
            /* router.push('/'); */
          } else {
            console.log('else currentAccount res-->', res);
            store.remove('accessToken');
            commit('SET_STATE', {
              id: '',
              name: '',
              role: '',
              email: '',
              avatar: '',
              authorized: false,
              loading: false,
            });
            /* router.push('/authentication/login'); */
          }
        });
      } else {
        /* router.push('/authentication/login'); */
      }
    },
    LOGOUT({
      commit,
      rootState
    }) {
      store.remove('accessToken');
      console.log('LOGOUT');
      const logout = mapAuthProviders[rootState.settings.authProvider].logout;
      logout().then(() => {
        store.remove('accessToken');
        store.remove('not_show_again_popup');
        commit('SET_STATE', {
          id: '',
          name: '',
          role: '',
          email: '',
          avatar: '',
          authorized: false,
          loading: false,
        });
        router.push('/authentication/login');
      });
    },
    GET({
      commit,
      rootState
    }, {
      payload
    }) {
      console.log('GET payload -->', payload);
      const get = mapAuthProviders[rootState.settings.authProvider].get;
      get(payload.path).then((res) => {
        // console.log('GET res -->', res);
        if (res && res.status == 401) {
          store.remove('accessToken');
          commit('SET_STATE', {
            id: '',
            name: '',
            role: '',
            email: '',
            avatar: '',
            authorized: false,
            loading: false,
          });
          router.push('/authentication/login');
        } else if (typeof payload.callback === 'function') {
          payload.callback(res.data);
        }
      });
    },
    POST({
      commit,
      rootState
    }, {
      payload
    }) {
      console.log('POST payload -->', payload);
      const post = mapAuthProviders[rootState.settings.authProvider].post;
      post(payload.path, payload.data, payload.responseType).then((res) => {
        console.log('POST res -->', res);
        if (res && res.status == 401) {
          store.remove('accessToken');
          commit('SET_STATE', {
            id: '',
            name: '',
            role: '',
            email: '',
            avatar: '',
            authorized: false,
            loading: false,
          });
          router.push('/authentication/login');
        } else if (typeof payload.callback === 'function') {
          payload.callback(res.data);
        }
      });
    },
    EXPORT({ commit, rootState }, { payload }) {
      console.log('POST payload -->', payload);
      const post = mapAuthProviders[rootState.settings.authProvider].post;
      post(payload.path, payload.data, payload.responseType).then((res) => {
        // console.log('POST res -->', res);
        if (res && res.status == 401) {
          store.remove('accessToken');
          commit('SET_STATE', {
            id: '',
            name: '',
            role: '',
            email: '',
            avatar: '',
            authorized: false,
            loading: false,
          });
          router.push('/auth/login');
        } else if (typeof payload.callback === 'function') {
          payload.callback(res);
        }
      });
    },
  },
  getters: {
    user: (state) => state,
  },
};