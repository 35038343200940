import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";

import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);

Vue.use(VueFeather);
Vue.use(VueSkycons, {
  color: "#FF0000",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.mixin({
  methods: {
    hasAccess: function (authorize, role_id) {
      // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
      return authorize.includes(role_id);
    },
    userData: function () {
      return this.$store.state.user.data
    },
  },
});
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");