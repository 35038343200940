import Vue from 'vue';
import Vuex from 'vuex';
import store from 'store';
Vue.use(Vuex);
const STORED_SETTINGS = (storedSettings) => {
  const settings = {};
  Object.keys(storedSettings).forEach((key) => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key];
  });
  return settings;
};
export default {
  state: {
    ...STORED_SETTINGS({
      authProvider: 'jwt', // firebase, jwt
      logo: 'Clean UI Pro',
      locale: 'th-TH',
      isSidebarOpen: false,
      isSupportChatOpen: false,
      isMobileView: false,
      isMobileMenuOpen: false,
      isMenuCollapsed: false,
      menuLayoutType: 'left', // left, top, nomenu
      routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
      menuColor: 'white', // white, dark, gray
      theme: 'default', // default, dark
      authPagesColor: 'white', // white, gray, image
      primaryColor: '#ff7105',
      leftMenuWidth: 256,
      isMenuUnfixed: false,
      isMenuShadow: false,
      isTopbarFixed: false,
      isGrayTopbar: false,
      isContentMaxWidth: false,
      isAppMaxWidth: false,
      isGrayBackground: false,
      isCardShadow: true,
      isSquaredBorders: false,
      isBorderless: false,
    }),
  },
  mutations: {
    CHANGE_SETTING(state, payload) {
      window.localStorage.setItem(
        `app.settings.${payload.setting}`,
        payload.value
      );
      state[payload.setting] = payload.value;
    },
    SETUP_URL_SETTINGS(state, payload) {
      let queryParams = payload;
      let keys = false;
      if (payload.redirect) {
        const str = payload.redirect;
        const subs = str.substring(str.indexOf('?') + 1);
        if (str.indexOf('?') >= 0) {
          queryParams = JSON.parse(
            '{"' +
            decodeURI(subs)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
            '"}'
          );
        }
      }
      delete queryParams.redirect;
      keys = Object.keys(queryParams);
      if (keys.length) {
        keys.forEach((key) => {
          let value;
          switch (queryParams[key]) {
            case 'false':
              value = false;
              break;
            case 'true':
              value = true;
              break;
            default:
              value = queryParams[key];
              break;
          }
          if (key in state) {
            state[key] = value;
          }
        });
      }
    },
    SET_PRIMARY_COLOR(state, payload) {
      const {
        color
      } = payload;
      const addStyles = () => {
        const styleElement = document.querySelector('#primaryColor');
        if (styleElement) {
          styleElement.remove();
        }
        const body = document.querySelector('body');
        const styleEl = document.createElement('style');
        const css = document.createTextNode(
          `:root { --kit-color-primary: ${color};}`
        );
        styleEl.setAttribute('id', 'primaryColor');
        styleEl.appendChild(css);
        body.appendChild(styleEl);
      };
      addStyles();
      state.primaryColor = color;
      store.set('app.settings.primaryColor', color);
    },
    SET_THEME(state, payload) {
      const {
        theme
      } = payload;
      const nextTheme = theme === 'dark' ? 'dark' : 'default';
      document.querySelector('html').setAttribute('data-kit-theme', nextTheme);
      state.theme = nextTheme;
      store.set('app.settings.theme', nextTheme);
    },
  },
  actions: {},
  getters: {
    state: (state) => state,
  },
};